import styled, { keyframes } from 'styled-components';

// Blue and white theme colors based on the new image provided
const primaryColor = '#4BA5F1'; // Exact blue color from the image logo
const backgroundColor = '#ffffff'; // White for backgrounds
const textColor = '#2a2a2a'; // Dark text for contrast
const buttonColor = '#4BA5F1'; // Match button blue with the logo blue color
const borderColor = '#e5e7eb'; // Light grey for borders
const secondaryTextColor = '#6b7280'; // Lighter grey text color for captions
const overlayColor = 'rgba(0, 0, 0, 0.5)'; // Overlay for modal background

// Font change to 'Univers'
const fontFamily = `'Univers', sans-serif`;

// Subtle scaling hover animation for buttons and hover effects
const hoverEffect = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
`;

// Main app container
export const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: ${backgroundColor};
  font-family: ${fontFamily};
`;

// Sidebar styling
export const Sidebar = styled.div`
  width: 200px;
  background-color: ${primaryColor}; // Updated to match logo blue
  color: ${backgroundColor}; // White text on blue background
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SidebarItem = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 18px;
  color: ${backgroundColor};
  &:hover {
    opacity: 0.8;
    animation: ${hoverEffect} 0.3s forwards;
  }
`;

export const SidebarBottom = styled.div`
  position: absolute;
  bottom: 20px;
  color: ${backgroundColor};
  font-size: 16px;
`;

// Main content and header
export const MainContent = styled.div`
  flex: 1;
  padding: 40px;
  background-color: ${backgroundColor};
  overflow-y: auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Logo = styled.img`
  height: 60px;
  transition: transform 0.3s ease;
  &:hover {
    transform: rotate(5deg) scale(1.1); // Add a hover effect to the logo
  }
`;

export const Button = styled.button`
  background-color: ${buttonColor}; // Blue for buttons to match logo color
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    animation: ${hoverEffect} 0.3s forwards;
    background-color: darken(${buttonColor}, 10%);
  }
`;

export const Input = styled.input`
  padding: 12px;
  margin-right: 10px;
  border: 1px solid ${borderColor};
  border-radius: 8px;
  font-size: 16px;
  width: 250px;
`;

export const TextArea = styled.textarea`
  padding: 12px;
  border: 1px solid ${borderColor};
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  min-height: 150px;
  resize: vertical;
  margin-bottom: 20px;
`;

export const BriefingSection = styled.div`
  margin-top: 30px;
  background-color: ${backgroundColor};
  border: 1px solid ${borderColor};
  padding: 20px;
  border-radius: 8px;
`;

export const InfluencerOptions = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const OptionButton = styled.button`
  background-color: ${buttonColor};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px;
  &:hover {
    background-color: darken(${buttonColor}, 10%);
    animation: ${hoverEffect} 0.3s forwards;
  }
`;

export const MessagingSection = styled.div`
  margin-top: 30px;
  background-color: ${backgroundColor};
  border: 1px solid ${borderColor};
  padding: 20px;
  border-radius: 8px;
`;

export const InfluencerInfo = styled.div`
  text-align: center;
  margin-bottom: 40px;
  
  h2 {
    font-size: 28px;
    margin-bottom: 10px;
    color: ${textColor};
  }

  p {
    font-size: 16px;
    color: ${secondaryTextColor};
    margin: 5px 0;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const PostCard = styled.div`
  background-color: ${backgroundColor};
  border: 1px solid ${borderColor};
  border-radius: 12px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
`;

export const PostImage = styled.img`
  width: 100%;
  height: auto;
  border-bottom: 1px solid ${borderColor};
  margin-bottom: 15px;
  border-radius: 12px;
`;

export const PostCaption = styled.div`
  height: 80px;
  overflow: hidden;
  text-align: left;
  margin-bottom: 10px;
  color: ${textColor};
`;

export const ReadMoreButton = styled.button`
  background: none;
  border: none;
  color: ${buttonColor};
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  display: block;
  &:hover {
    text-decoration: underline;
    animation: ${hoverEffect} 0.3s forwards;
  }
`;

export const PostStats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px;
  color: ${secondaryTextColor};
`;

export const StatItem = styled.p`
  margin: 0;
`;

export const GraphContainer = styled.div`
  margin-top: 40px;
  text-align: center;

  h3 {
    margin-bottom: 20px;
    font-size: 24px;
    color: ${textColor};
  }
`;

// ========== Welcome Page and Modal Styles ==========

// Welcome page container styling with frame
export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  background: linear-gradient(135deg, ${primaryColor}, #6fc4f2);
  font-family: ${fontFamily};
  color: ${backgroundColor};
  border: 3px solid ${primaryColor}; // Frame around the welcome page
  border-radius: 15px;
  padding: 50px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.15); // Adds a shadow for depth
`;

// Welcome page header with logo and login button
export const Header = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;

  h1 {
    font-size: 24px;
    margin-right: auto;
    color: ${backgroundColor};
  }
`;

// Welcome text styling with shadow for depth and animation
const fancyTextAnimation = keyframes`
  0% { opacity: 0; transform: translateY(20px); }
  50% { opacity: 0.5; transform: translateY(-10px); }
  100% { opacity: 1; transform: translateY(0); }
`;

export const WelcomeText = styled.h1`
  font-size: 56px;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3); // Adds shadow for depth
  animation: ${fancyTextAnimation} 1.5s ease-out forwards; // Fancy animation
`;

// Action button for login on welcome page with hover animation
export const ActionButton = styled.button`
  background-color: ${backgroundColor};
  color: ${primaryColor};
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    animation: ${hoverEffect} 0.3s forwards;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
`;

// Modal styling for login with proportions and frame
export const LoginModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${backgroundColor};
  padding: 40px;
  border-radius: 10px;
  z-index: 1001;
  width: 380px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); // Adds more depth to the popup
  border: 2px solid ${borderColor}; // Adds a clean border
`;

// Overlay for login modal
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${overlayColor};
  z-index: 1000;
`;

// Close button inside modal
export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

// Login form styling
export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

// Styling for the inputs in the login form
export const LoginInput = styled(Input)`
  margin-bottom: 15px;
  padding: 14px; // Increase padding for a cleaner look
  font-size: 18px;
  border: 1px solid ${borderColor};
  border-radius: 8px;
  font-family: ${fontFamily};
`;

// Styling for login button inside the modal
export const LoginButton = styled.button`
  background-color: ${primaryColor};
  color: white;
  padding: 14px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: darken(${primaryColor}, 10%);
  }
`;

// ========== Messaging and Chat UI ==========

export const MessageIconSection = styled.div`
  background-color: ${backgroundColor};
  border: 1px solid ${borderColor};
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin-bottom: 20px;
`;

export const ChatList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
`;

export const ChatItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${borderColor};
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const MessageChatSection = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .chat-window {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    overflow-y: auto;
    background-color: #f7fafc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

export const ChatMessage = styled.div`
  align-self: ${({ sentByUser }) => (sentByUser ? 'flex-end' : 'flex-start')};
  background-color: ${({ sentByUser }) => (sentByUser ? '#4BA5F1' : '#E2E8F0')};
  color: ${({ sentByUser }) => (sentByUser ? '#fff' : '#000')};
  padding: 10px 15px;
  border-radius: 15px;
  max-width: 60%;
  word-wrap: break-word;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  span {
    font-size: 0.8rem;
    color: ${({ sentByUser }) => (sentByUser ? '#d1d1d1' : '#555')};
    margin-top: 5px;
  }
`;

export const ChatInputSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid ${borderColor};
`;

export const SendButton = styled.button`
  background-color: ${buttonColor};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #3b94e1;
  }
`;

export const CloseButtonChat = styled.button`
  background-color: #f56565;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #e53e3e;
  }
`;
