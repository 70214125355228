import React, { useState } from 'react';
import { FaHome, FaChartBar, FaCalendarAlt, FaPlusCircle, FaSearch, FaUserCircle, FaEnvelope } from 'react-icons/fa';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import {
  Container, Sidebar, SidebarItem, SidebarBottom, MainContent, HeaderContainer, Logo, Button, Input,
  GridContainer, PostCard, PostImage, PostCaption, PostStats, StatItem, InfluencerInfo, ReadMoreButton,
  GraphContainer, TextArea, InfluencerOptions, OptionButton, MessagingSection, WelcomeContainer, WelcomeText, ActionButton, LoginModal, Overlay, CloseButton, Header, LoginForm, MessageIconSection, ChatList, ChatItem, MessageChatSection, ChatMessage, ChatInputSection, SendButton, CloseButtonChat
} from './StyledComponents';  
import { WishListProvider } from './WishListContext';
import { apiLogin, fetchInstagramData } from './api';
import jsPDF from 'jspdf'; // For generating PDF

// Translation object for languages with all required keys
const translations = {
  en: {
    welcomeText: 'Your next big idea starts here',
    getStartedText: 'Get started by connecting with the best influencers in the industry.',
    login: 'Login',
    searchPlaceholder: 'Search for influencers by Instagram username',
    message: 'Message',
    createBriefing: 'Create Briefing',
  },
  de: {
    welcomeText: 'Ihre nächste große Idee beginnt hier',
    getStartedText: 'Fangen Sie an, indem Sie sich mit den besten Influencern der Branche verbinden.',
    login: 'Einloggen',
    searchPlaceholder: 'Suchen Sie nach Influencern mit Instagram-Benutzernamen',
    message: 'Nachricht',
    createBriefing: 'Briefing erstellen',
  },
  fr: {
    welcomeText: 'Votre prochaine grande idée commence ici',
    getStartedText: 'Commencez en vous connectant avec les meilleurs influenceurs de l\'industrie.',
    login: 'Connexion',
    searchPlaceholder: 'Recherchez des influenceurs par nom d\'utilisateur Instagram',
    message: 'Message',
    createBriefing: 'Créer un briefing',
  },
  es: {
    welcomeText: 'Tu próxima gran idea comienza aquí',
    getStartedText: 'Comienza conectándote con los mejores influencers de la industria.',
    login: 'Iniciar sesión',
    searchPlaceholder: 'Busca influencers por nombre de usuario de Instagram',
    message: 'Mensaje',
    createBriefing: 'Crear briefing',
  },
  ru: {
    welcomeText: 'Ваша следующая большая идея начинается здесь',
    getStartedText: 'Начните, подключившись к лучшим инфлюенсерам в отрасли.',
    login: 'Войти',
    searchPlaceholder: 'Поиск инфлюенсеров по имени пользователя Instagram',
    message: 'Сообщение',
    createBriefing: 'Создать брифинг',
  }
};

const InfluenceApp = () => {
  const [instagramData, setInstagramData] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [expandedPost, setExpandedPost] = useState(null);
  const [showMessaging, setShowMessaging] = useState(false);
  const [showBriefing, setShowBriefing] = useState(false);
  const [briefingData, setBriefingData] = useState({
    campaignName: '', brandProduct: '', objective: '', targetAudience: '', keyMessage: '', 
    platforms: '', contentType: '', creativeDirection: '', talkingPoints: '', dos: '', donts: '',
    contentDeadline: '', postingDates: '', reviewProcess: '', compensation: '', deliverables: '',
    hashtags: '', tagUs: '', usageRights: '', contactPerson: '', contactEmail: '', contactPhone: ''
  });
  const [message, setMessage] = useState('');
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isMessageSectionOpen, setIsMessageSectionOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const [language, setLanguage] = useState('en'); // Default language is English

  // Function to handle user login
  const handleLogin = async () => {
    const token = await apiLogin(email, password);
    if (token) {
      setToken(token);
      setIsLoggedIn(true);
      setShowModal(false);
      setHasLoggedIn(true);
    } else {
      alert('Failed to log in');
    }
  };

  // Function to fetch Instagram data after logging in
  const handleFetchData = async () => {
    if (!username || !token) return;
    const data = await fetchInstagramData(username, token);
    if (data) {
      const formattedData = {
        fullName: data.instagram_full_name,
        followers: data.followers,
        reach: data.posts.reduce((acc, post) => acc + (post.likes || 0), 0),
        posts: data.posts.map(post => ({
          id: post.instagram_id,
          imageUrl: post.media_url,
          description: post.description,
          likes: post.likes,
          comments: post.comments,
        })),
      };
      setInstagramData(formattedData);
    } else {
      alert('Failed to fetch data');
    }
  };

  // Function to toggle Read More/Read Less for posts with long captions
  const toggleReadMore = (postId) => {
    setExpandedPost(expandedPost === postId ? null : postId);
  };

  // Function to view chat section
  const handleViewChats = () => {
    setIsMessageSectionOpen(true);
  };

  // Function to send a message to the influencer and store it in the chat history
  const handleSendMessage = () => {
    if (instagramData && message) {
      const newChat = {
        influencer: instagramData.fullName,
        message,
        timestamp: new Date().toLocaleString(),
      };
      setChats([...chats, newChat]); // Add to chat history
      alert(`Message sent to ${instagramData.fullName}: ${message}`);
      setMessage(''); // Clear message after sending
    }
  };

  // Function to cancel the message being written
  const handleCancelMessage = () => {
    setMessage(''); // Clear the message
  };

  // Function to create a PDF from briefing data
  const handleSavePDF = () => {
    const doc = new jsPDF();
    doc.text(`Campaign Name: ${briefingData.campaignName}`, 10, 10);
    doc.text(`Brand/Product: ${briefingData.brandProduct}`, 10, 20);
    doc.text(`Objective: ${briefingData.objective}`, 10, 30);
    doc.text(`Target Audience: ${briefingData.targetAudience}`, 10, 40);
    doc.text(`Key Message: ${briefingData.keyMessage}`, 10, 50);
    doc.text(`Platform(s): ${briefingData.platforms}`, 10, 60);
    doc.text(`Content Type: ${briefingData.contentType}`, 10, 70);
    doc.text(`Creative Direction: ${briefingData.creativeDirection}`, 10, 80);
    doc.text(`Talking Points: ${briefingData.talkingPoints}`, 10, 90);
    doc.text(`Do's: ${briefingData.dos}`, 10, 100);
    doc.text(`Don'ts: ${briefingData.donts}`, 10, 110);
    doc.text(`Content Deadline: ${briefingData.contentDeadline}`, 10, 120);
    doc.text(`Posting Dates: ${briefingData.postingDates}`, 10, 130);
    doc.text(`Review Process: ${briefingData.reviewProcess}`, 10, 140);
    doc.text(`Compensation: ${briefingData.compensation}`, 10, 150);
    doc.text(`Deliverables: ${briefingData.deliverables}`, 10, 160);
    doc.text(`Hashtags: ${briefingData.hashtags}`, 10, 170);
    doc.text(`Tag Us: ${briefingData.tagUs}`, 10, 180);
    doc.text(`Usage Rights: ${briefingData.usageRights}`, 10, 190);
    doc.text(`Contact: ${briefingData.contactPerson}`, 10, 200);
    doc.text(`Email: ${briefingData.contactEmail}`, 10, 210);
    doc.text(`Phone: ${briefingData.contactPhone}`, 10, 220);
    doc.save('Influencer_Briefing.pdf');
  };

  // Function to discard the briefing
  const handleDiscardBriefing = () => {
    setBriefingData({
      campaignName: '', brandProduct: '', objective: '', targetAudience: '', keyMessage: '', 
      platforms: '', contentType: '', creativeDirection: '', talkingPoints: '', dos: '', donts: '',
      contentDeadline: '', postingDates: '', reviewProcess: '', compensation: '', deliverables: '',
      hashtags: '', tagUs: '', usageRights: '', contactPerson: '', contactEmail: '', contactPhone: ''
    });
    setShowBriefing(false);
  };

  // Handle language change
  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  return (
    <WishListProvider>
      {!hasLoggedIn ? (
        <WelcomeContainer>
          <Header>
            <Logo src="/logo.png" alt="InfluenceMe Logo" />  
            <ActionButton onClick={() => setShowModal(true)}>{translations[language].login}</ActionButton>
          </Header>
          <WelcomeText>{translations[language].welcomeText}</WelcomeText>
          <p>{translations[language].getStartedText}</p>

          {/* Language selection dropdown */}
          <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
            <select value={language} onChange={handleLanguageChange}>
              <option value="en">English</option>
              <option value="de">German</option>
              <option value="fr">French</option>
              <option value="es">Spanish</option>
              <option value="ru">Russian</option>
            </select>
          </div>

          {showModal && (
            <>
              <Overlay onClick={() => setShowModal(false)} />
              <LoginModal>
                <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
                <h2>{translations[language].login}</h2>
                <LoginForm>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button onClick={handleLogin}>{translations[language].login}</Button>
                </LoginForm>
              </LoginModal>
            </>
          )}
        </WelcomeContainer>
      ) : (
        <Container>
          <Sidebar>
            <div>
              <SidebarItem><FaHome /></SidebarItem>
              <SidebarItem><FaChartBar /></SidebarItem>
              <SidebarItem><FaCalendarAlt /></SidebarItem>
              <SidebarItem><FaPlusCircle /></SidebarItem>
              <SidebarItem><FaSearch /></SidebarItem>
              <SidebarItem onClick={handleViewChats}><FaEnvelope /></SidebarItem>
            </div>
            <SidebarBottom>
              <FaUserCircle size={30} />
              <span>Aditya</span>
            </SidebarBottom>
          </Sidebar>

          <MainContent>
            <HeaderContainer>
              <Logo src="/logo.png" alt="InfluenceMe Logo" />
              {isLoggedIn && (
                <>
                  <Input
                    type="text"
                    placeholder={translations[language].searchPlaceholder}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleFetchData()}
                  />
                  <Button onClick={handleFetchData}>{translations[language].search}</Button>
                </>
              )}
            </HeaderContainer>

            {/* Messaging Section */}
            {isMessageSectionOpen && (
              <MessageIconSection>
                <h2>Chats with Influencers</h2>
                <ChatList>
                  {chats.map((chat, index) => (
                    <ChatItem key={index} onClick={() => setSelectedChat(chat)}>
                      <p><strong>{chat.influencer}</strong></p>
                      <p>{chat.message}</p>
                      <p>{chat.timestamp}</p>
                    </ChatItem>
                  ))}
                </ChatList>

                {selectedChat && (
                  <MessageChatSection>
                    <div className="chat-window">
                      <ChatMessage sentByUser={true}>
                        {selectedChat.message}
                        <span>{selectedChat.timestamp}</span>
                      </ChatMessage>
                    </div>
                    <ChatInputSection>
                      <TextArea
                        placeholder="Reply to this chat..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <SendButton onClick={handleSendMessage}>Send</SendButton>
                      <CloseButtonChat onClick={handleCancelMessage}>Cancel</CloseButtonChat>
                    </ChatInputSection>
                  </MessageChatSection>
                )}
              </MessageIconSection>
            )}

            {instagramData && (
              <div>
                <InfluencerInfo>
                  <h2>{instagramData.fullName}</h2>
                  <p>Followers: {instagramData.followers}</p>
                  <p>Reach: {instagramData.reach}</p>
                </InfluencerInfo>

                <InfluencerOptions>
                  <OptionButton onClick={() => setShowMessaging(true)}>{translations[language].message}</OptionButton>
                  <OptionButton onClick={() => setShowBriefing(true)}>{translations[language].createBriefing}</OptionButton>
                </InfluencerOptions>

                {/* Briefing Form */}
                {showBriefing && (
                  <div className="briefing-form">
                    <h3>Create Professional Briefing for {instagramData.fullName}</h3>
                    <Input placeholder="Campaign Name" value={briefingData.campaignName} onChange={(e) => setBriefingData({ ...briefingData, campaignName: e.target.value })} />
                    <Input placeholder="Brand/Product" value={briefingData.brandProduct} onChange={(e) => setBriefingData({ ...briefingData, brandProduct: e.target.value })} />
                    <Input placeholder="Objective" value={briefingData.objective} onChange={(e) => setBriefingData({ ...briefingData, objective: e.target.value })} />
                    <Input placeholder="Target Audience" value={briefingData.targetAudience} onChange={(e) => setBriefingData({ ...briefingData, targetAudience: e.target.value })} />
                    <TextArea placeholder="Key Message" value={briefingData.keyMessage} onChange={(e) => setBriefingData({ ...briefingData, keyMessage: e.target.value })} />
                    <Input placeholder="Platform(s)" value={briefingData.platforms} onChange={(e) => setBriefingData({ ...briefingData, platforms: e.target.value })} />
                    <Input placeholder="Content Type" value={briefingData.contentType} onChange={(e) => setBriefingData({ ...briefingData, contentType: e.target.value })} />
                    <TextArea placeholder="Creative Direction" value={briefingData.creativeDirection} onChange={(e) => setBriefingData({ ...briefingData, creativeDirection: e.target.value })} />
                    <TextArea placeholder="Talking Points" value={briefingData.talkingPoints} onChange={(e) => setBriefingData({ ...briefingData, talkingPoints: e.target.value })} />
                    <TextArea placeholder="Do's" value={briefingData.dos} onChange={(e) => setBriefingData({ ...briefingData, dos: e.target.value })} />
                    <TextArea placeholder="Don'ts" value={briefingData.donts} onChange={(e) => setBriefingData({ ...briefingData, donts: e.target.value })} />
                    <Input type="date" placeholder="Content Deadline" value={briefingData.contentDeadline} onChange={(e) => setBriefingData({ ...briefingData, contentDeadline: e.target.value })} />
                    <Input type="date" placeholder="Posting Dates" value={briefingData.postingDates} onChange={(e) => setBriefingData({ ...briefingData, postingDates: e.target.value })} />
                    <TextArea placeholder="Review Process" value={briefingData.reviewProcess} onChange={(e) => setBriefingData({ ...briefingData, reviewProcess: e.target.value })} />
                    <Input placeholder="Compensation" value={briefingData.compensation} onChange={(e) => setBriefingData({ ...briefingData, compensation: e.target.value })} />
                    <TextArea placeholder="Deliverables" value={briefingData.deliverables} onChange={(e) => setBriefingData({ ...briefingData, deliverables: e.target.value })} />
                    <Input placeholder="Hashtags" value={briefingData.hashtags} onChange={(e) => setBriefingData({ ...briefingData, hashtags: e.target.value })} />
                    <Input placeholder="Tag Us (@YourBrandHandle)" value={briefingData.tagUs} onChange={(e) => setBriefingData({ ...briefingData, tagUs: e.target.value })} />
                    <TextArea placeholder="Usage Rights" value={briefingData.usageRights} onChange={(e) => setBriefingData({ ...briefingData, usageRights: e.target.value })} />
                    <Input placeholder="Contact Person" value={briefingData.contactPerson} onChange={(e) => setBriefingData({ ...briefingData, contactPerson: e.target.value })} />
                    <Input placeholder="Contact Email" value={briefingData.contactEmail} onChange={(e) => setBriefingData({ ...briefingData, contactEmail: e.target.value })} />
                    <Input placeholder="Contact Phone" value={briefingData.contactPhone} onChange={(e) => setBriefingData({ ...briefingData, contactPhone: e.target.value })} />
                    <Button onClick={handleSavePDF}>Save as PDF</Button>
                    <Button onClick={handleDiscardBriefing}>Discard</Button>
                  </div>
                )}

                {/* Graph and Posts */}
                <GraphContainer>
                  <h3>Post Stats (Likes and Comments)</h3>
                  <LineChart width={600} height={300} data={instagramData.posts}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="description" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="likes" stroke="#8884d8" />
                    <Line type="monotone" dataKey="comments" stroke="#82ca9d" />
                  </LineChart>
                </GraphContainer>

                <GridContainer>
                  {instagramData.posts.map(post => (
                    <PostCard key={post.id}>
                      <PostImage src={post.imageUrl} alt="Post Image" />
                      <PostCaption>
                        <p>
                          {expandedPost === post.id || post.description.length <= 100
                            ? post.description
                            : `${post.description.substring(0, 100)}...`}
                        </p>
                        {post.description.length > 100 && (
                          <ReadMoreButton onClick={() => toggleReadMore(post.id)}>
                            {expandedPost === post.id ? 'Read Less' : 'Read More'}
                          </ReadMoreButton>
                        )}
                      </PostCaption>
                      <PostStats>
                        <StatItem>Likes: {post.likes}</StatItem>
                        <StatItem>Comments: {post.comments}</StatItem>
                      </PostStats>
                    </PostCard>
                  ))}
                </GridContainer>
              </div>
            )}
          </MainContent>
        </Container>
      )}
    </WishListProvider>
  );
};

export default InfluenceApp;