export const apiLogin = async (email, password) => {
  try {
    const response = await fetch('https://platform.influenceme.de/api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify({ email, password }), 
    });

    if (!response.ok) {
      throw new Error('Login failed'); 
    }

    const data = await response.json(); 
    return data.token; 
  } catch (error) {
    console.error('Error logging in:', error); 
    return null; 
  }
};

export const fetchInstagramData = async (username, token) => {
  try {
    const response = await fetch(`https://platform.influenceme.de/api/influencer/instagram/${username}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Instagram data'); 
    }

    const data = await response.json(); 
    return data; 
  } catch (error) {
    console.error('Error fetching Instagram data:', error); 
    return null; 
  }
};
